import CartActionTypes from '../actionTypes/cart'

const initialState = {
  client: null,
  checkout: null,
  checkoutUrl: null,
  items: [],
  subTotal: null,
  estimatedShipping: null,
  estimatedTax: null,
  taxesIncluded: null,
  totalPrice: null,
  currencyCode: null,
  couponCode: null,
  isUpdating: false,
  isUpdated: false,
  isAdded: false,
  removing: [],
  removed: []
};

export default (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case CartActionTypes.INIT_ACTION:
      return {
        ...state,
        client: payload.client,
        checkout: payload.checkout,
        removing: [],
        removed: []
      };
    case CartActionTypes.UPDATING_ACTION:
      return {
        ...state,
        ...payload,
        isUpdating: true,
      };
    case CartActionTypes.UPDATE_ACTION:
      return {
        ...state,
        isUpdating: false,
        checkout: payload.checkout,
        checkoutUrl: payload.checkoutUrl,
        items: payload.items,
        subTotal: payload.subTotal,
        estimatedShipping: payload.estimatedShipping,
        totalPrice: payload.totalPrice,
        isUpdated: payload.isUpdated,
        isAdded: payload.isAdded,
        isToggleBasket: payload.isToggleBasket,
        couponCode: payload.couponCode,
        removing: [],
        removed: []
      };
    case CartActionTypes.UPDATED_ACTION:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
}

export function getCheckout(store) {
  return {
    items: store.items,
    checkoutUrl: store.checkoutUrl,
    couponCode: store.couponCode,
    isUpdated: store.isUpdated
  }
}

export function getCart(store) {
  return store
}

export function isUpdating(store) {
  return store.isUpdating
}

export function isAdded(store) {
  return store.isAdded
}

export function isToggleBasket(store) {
  return store.isToggleBasket
}

export function isUpdated(store) {
  return store.isUpdated
}

export function getRemovingItems(store) {
  return store.removing;
}

export function getRemovedItems(store) {
  return store.removed;
}

export function getItemsCount(store) {
  let count = 0;

  if (store.checkout) {
    let lineItems = store.checkout.lineItems;

    lineItems.forEach((lineItem) => {
      count += lineItem.quantity;
    });
  }

  return count;
}
