// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-text-page-js": () => import("./../src/templates/textPage.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-age-verification-js": () => import("./../src/pages/age-verification.js" /* webpackChunkName: "component---src-pages-age-verification-js" */),
  "component---src-pages-beers-detail-js": () => import("./../src/pages/beers-detail.js" /* webpackChunkName: "component---src-pages-beers-detail-js" */),
  "component---src-pages-beers-js": () => import("./../src/pages/beers.js" /* webpackChunkName: "component---src-pages-beers-js" */),
  "component---src-pages-brewery-js": () => import("./../src/pages/brewery.js" /* webpackChunkName: "component---src-pages-brewery-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-foundation-js": () => import("./../src/pages/foundation.js" /* webpackChunkName: "component---src-pages-foundation-js" */),
  "component---src-pages-futures-js": () => import("./../src/pages/futures.js" /* webpackChunkName: "component---src-pages-futures-js" */),
  "component---src-pages-introducing-js": () => import("./../src/pages/introducing.js" /* webpackChunkName: "component---src-pages-introducing-js" */),
  "component---src-pages-invest-js": () => import("./../src/pages/invest.js" /* webpackChunkName: "component---src-pages-invest-js" */),
  "component---src-pages-net-js": () => import("./../src/pages/net.js" /* webpackChunkName: "component---src-pages-net-js" */),
  "component---src-pages-shipmates-js": () => import("./../src/pages/shipmates.js" /* webpackChunkName: "component---src-pages-shipmates-js" */),
  "component---src-pages-store-js": () => import("./../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-voyage-js": () => import("./../src/pages/voyage.js" /* webpackChunkName: "component---src-pages-voyage-js" */),
  "component---src-pages-we-can-js": () => import("./../src/pages/we-can.js" /* webpackChunkName: "component---src-pages-we-can-js" */)
}

