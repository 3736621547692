import {combineReducers} from 'redux'
import cart, * as CartSelectors from './cart'

const CART = 'CART';

const reducers = {
  [CART]: cart
};

export default combineReducers(reducers)

export function getCheckout(store) {
  return CartSelectors.getCheckout(store[CART])
}

export function getCart(store) {
  return CartSelectors.getCart(store[CART])
}

export function isCartUpdating(store) {
  return CartSelectors.isUpdating(store[CART])
}

export function isCartUpdated(store) {
  return CartSelectors.isUpdated(store[CART])
}

export function isCartItemAdded(store) {
  return CartSelectors.isAdded(store[CART])
}

export function isToggleBasket(store) {
  return CartSelectors.isToggleBasket(store[CART])
}

export function getRemovingItems (store) {
  return CartSelectors.getRemovingItems(store[CART]);
}

export function getRemovedItems (store) {
  return CartSelectors.getRemovedItems(store[CART]);
}

export function getItemsCount (store) {
  return CartSelectors.getItemsCount(store[CART]);
}
